<!--
 * @Description:: 请补充填写
 * @Version: 请配置版本号
 * @Author: xiaobai
 * @Date: 2021-06-10 17:30:51
 * @LastEditors: smallWhite
 * @LastEditTime: 2024-07-24 16:08:30
 * @FilePath: \sipingnongzhuan2\src\components\footer.vue
-->
<template>
  <div
    class="content footer_box">
    <div class="content_1260">
      <div class="el-row">
        <div
          class="el-col el-col-24 el-col-sm-24 el-col-md-24 el-col-lg-24">
          <div
            class="footer_text">
            <span
              class="mr-30">
              <i
                class="el-icon-phone mr-10"></i>
              <span>联系电话：{{contact.mobile}}</span>
            </span>
            <span
              class="mr-30">
              <i
                class="el-icon-message mr-10"></i>
              <span>电子邮箱：{{contact.email}}</span>
            </span>
            <span
              class="mr-30">
              <i
                class="el-icon-location-information mr-10"></i>
              <span>单位地址：{{contact.address}}</span>
            </span>
          </div>
          <div class="mt-20">
            <span> CopyRight ©
              2023
              四平农村成人高等专科学校
            </span>
            <span>All Right
              Reserved. |
              <span
                @click="openPage()">吉ICP备2024017895号-1</span></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      contact: { mobile: '0434-3099969', address: '吉林省四平市铁西区中央西路3538号', email: 'spnz@163.com' }
    }
  },
  mounted() {
    // this.getContact()
  },
  methods: {
    getContact() {
      axios.post('/home/contact/us', {}).then(res => {
        this.contact = res.data.data.contactUs
      })
    },
    openPage() {
      window.open('https://beian.miit.gov.cn')
    }
  }
}
</script>

<style>
</style>