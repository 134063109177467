<!--
 * @Description:: 请补充填写
 * @Version: 请配置版本号
 * @Author: xiaobai
 * @Date: 2021-06-10 14:31:17
 * @LastEditors: smallWhite
 * @LastEditTime: 2024-02-26 08:43:12
 * @FilePath: \sipingnongzhuan2\src\components\header_index.vue
-->
<template>
  <div
    style="background:#fff">
    <div
      v-if="isPhone ==='false'"
      style="background:#1B6FCF">
      <!-- pc -->
      <div class="top_logo">
        <img
          class="top_logo_img"
          src="../assets/imgaes/xuexiao_logo3.png" />
        <div
          style="margin-top:56px">
          <div
            style="font-size:40px;width:40px;height:102px;overflow: hidden;float: left;margin-top:8px;margin-right:10px;color:white;"
            class="el-icon-phone-outline">
          </div>
          <div
            style="width:200px;height:102px;overflow: hidden">
            <p
              style="color:#fff">
              招办电话：0434-3099938
            </p>
            <p
              style="color:#fff;margin-top:10px">
              校办电话：0434-3099969
            </p>
          </div>
        </div>
      </div>
      <div class="header_boxs"
        :class="bgWhite">
        <div class="header">
          <div
            class="nav_boxs">
            <ul
              class="web_menu">
              <li
                class="web_menu_item"
                :class="{'nav_active':isActive== index}"
                v-for="(item,index) in navList"
                :key="index">
                <div
                  v-if="item.name == '首页'"
                  @click="routerPage(item)">
                  {{item.name}}
                </div>
                <el-dropdown
                  v-if="item.path !='/xi' && item.path !='/lb'"
                  :show-timeout="0"
                  :hide-timeout="200"
                  @command="handleCommand">
                  <span
                    style="font-size:16px;color:#fff"
                    class="el-dropdown-link">
                    {{item.name}}<i
                      class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu
                    slot="dropdown">
                    <el-dropdown-item
                      v-for="(k,j) in item.children"
                      :key='j'
                      :command="k">{{k.name}}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div
      style="background:#1B6FCF"
      v-else>
      <!-- phone -->
      <div class="top_logo"
        :style="{marginLeft:'5px',width:'100%',height:phoneWidth + 'px'}"
        v-if="isPhone ==='true'">
        <img
          :style="{width:'70%',height:phoneWidth *0.7+'px'}"
          class="top_logo_img"
          src="../assets/imgaes/xuexiao_logo3.png" />
        <i style="color:#fff;text-align:right;margin-right:15px;font-size:20px"
          class="el-icon-s-fold"
          @click="goMenu"></i>
      </div>
    </div>
    <menu-view ref="menuv"
      v-if="$store.state.path != '/home'"></menu-view>
    <Menu ref="menu"></Menu>
  </div>
</template>

<script>
import axios from 'axios'
import Menu from './menuP.vue'
export default {
  components: { Menu },
  data() {
    return {
      isActive: 0,
      open_nav: 0,
      isPhone: false,
      open_navs: 0,
      bgWhite: '',
      openMobile: '',
      scrollTop: 0,
      phoneWidth: 0,
      top: '',
      toPath1: '',
      toPath: '/home',
      menuList: [],
      openNav: []
    }
  },
  watch: {
    $route(to, from) {
      console.log('路由变化了')
      console.log('当前页面路由：' + to.path)
      console.log(from)
      this.toPath1 = to.path
      // if (to.path == from.path) {
      //   alert(133)
      // }
      // this.gotoPage()
    }
  },
  created() {
    this.phoneWidth = window.screen.width / 5.3
    this.navList = JSON.parse(localStorage.getItem('navList'))
    // this.$post('/project/selectListForClient', {}).then(res => {
    //   console.log(res)
    // })
  },
  beforeRouteUpdate(to, from, next) {
    console.log(to)
    console.log(from)
    // 在页面刷新时执行的操作
    this.$router.push({ path: this.$store.state.path, query: { id: this.$store.state.id } })
    // ...
    next()
  },
  mounted() {
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
    window.addEventListener('load', () => {
      this.$router.push({ path: this.$store.state.path, query: { id: this.$store.state.id } })
    })
    this.isPhone = window.localStorage.getItem('isPhone')
    window.addEventListener('scroll', this.getHtmlBodyHeight)
    // this.getMent()
    this.openNav = this.$store.state.openNav
    if (!this.openNav || this.openNav[0] == 0 || this.openNav.length == 0) {
      this.bgWhite = ''
    } else {
      this.isActive = this.openNav[0]
      this.bgWhite = 'bg-white'
    }
  },
  methods: {
    // 手机导航
    goMenu() {
      this.$refs.menu.open()
    },
    beforeunloadHandler(e) {
      console.log(123)
      console.log(e)
      this.$router.push({ path: this.$store.state.path, query: { id: this.$store.state.id } })
    },
    handleCommand(command) {
      console.log(command)
      this.$nextTick(() => {
        if (this.toPath1 == this.$store.state.path) {
          this.$refs.menuv.getRoute()
        }
      })
      this.$store.commit('PATH', command.path)
      this.$store.commit('ID', command.id)
      this.routerPage(command)
    },
    routerPage(command) {
      this.$router.push({ path: command.path, query: { id: command.id } })
      this.goTop()
    },
    gotoPage(index, j) {
      console.log(index, j)
      this.openMobile = ''
      this.open_navs = 0
      this.$store.commit('setOpenNav', [index, j])
      this.isActive = index
      if (index == 1) {
        if (j == 0) this.$router.push('/company_profile')
        // else if (j == 0.5) this.$router.push('/development_history')
        else if (j == 1) this.$router.push('/development_history')
        else if (j == 2) this.$router.push('/management_team')
        else if (j == 3) this.$router.push('/leadership_care')
      } else if (index == 2) {
        if (j == 0) this.$router.push('/highway_project')
        else if (j == 1) this.$router.push('/government_project')
        else if (j == 2) this.$router.push('/bridge_project')
        else if (j == 3) this.$router.push('/tunnel_project')
        else if (j == 4) this.$router.push('/intellectualization')
      } else if (index == 3) {
        if (j == 0) this.$router.push('/professional_qualification')
        else if (j == 1) this.$router.push('/certificate_of_honor')
        else if (j == 2) this.$router.push('/technological_innovation')
      } else if (index == 4) {
        if (j == 0) this.$router.push('/project_way_index')
        else if (j == 1) this.$router.push('/project_government')
        else if (j == 2) this.$router.push('/investment_business')
        else if (j == 3) this.$router.push('/engineering_inspection')
      } else if (index == 5) {
        if (j == 0) this.$router.push('/cultural_concept')
        else if (j == 1) this.$router.push('/safe_production')
        else if (j == 2) this.$router.push('/craftsman_spirit')
      } else if (index == 6) {
        if (j == 0) this.$router.push('/industry_news')
        else if (j == 1) this.$router.push('/company_news')
        else if (j == 2) this.$router.push('/party_building')
        else if (j == 3) this.$router.push('/video_news')
        else if (j == 4) this.$router.push('/shape')
      } else if (index == 7) {
        if (j == 0) this.$router.push('/job_recruitment')
        else if (j == 1) this.$router.push('/people_develoment')
        else if (j == 2) this.$router.push('/team_building')
      }
      this.goTop()
    },
    getMent() {
      axios.post('/project/selectListForClient', {}).then(res => {
        console.log(res)
        this.menuList = res.data.data.projectList
        console.log(this.menuList)
        this.$store.commit('setNavList', this.menuList)
      })
    },
    mouseover(e) {
      this.open_nav = e
    },
    mouseleave() {
      this.open_nav = 0
    },
    openNaves(index) {
      this.open_navs = index
    },
    open() {
      this.openMobile = 'open_mobile'
    },
    close() {
      this.openMobile = ''
    },
    getHtmlBodyHeight() {
      //获取滚动时的高度
      const openNav = this.$store.state.openNav
      if (openNav[0] == 0) {
        this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        if (this.scrollTop < 430) {
          this.top = 430 - this.scrollTop - 30
        } else {
          this.bgWhite = ''
        }
      }
    },
    goTop() {
      var timer = null
      cancelAnimationFrame(timer)
      timer = requestAnimationFrame(function fn() {
        var oTop = document.body.scrollTop || document.documentElement.scrollTop
        if (oTop > 0) {
          document.body.scrollTop = document.documentElement.scrollTop = oTop - 50
          timer = requestAnimationFrame(fn)
        } else {
          cancelAnimationFrame(timer)
        }
      })
    }
  }
}
</script>

<style>
.second_menu {
  height: 0 !important;
  overflow: hidden;
  opacity: 1 !important;
}
/* 后加的 */
.top_logo {
  width: 1200px;
  height: 160px;
  background: #1b6fcf;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}
.top_logo_img {
  height: 105px;
}
.header_boxs {
  background: #105bb2;
}
</style>