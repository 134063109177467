<!--
 * @Description:: 育小帮科技有限公司
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-02-14 10:32:02
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-11-20 15:54:54
 * @FilePath: \sipingnongzhuan2\src\components\menuP.vue
-->
<template>
  <!--弹窗-->
  <!-- <el-dialog
    :visible.sync="dialogVisible"
    width="100%"
    :close-on-click-modal="false"
    :before-close="handleClose">
  </el-dialog> -->
  <el-drawer title="我是标题"
    size="50%"
    :visible.sync="dialogVisible"
    :with-header="false">
    <el-menu
      :unique-opened='true'
      class="el-menu-vertical-demo">
      <el-menu-item index="1"
        @click="goPage(home)">
        <i class="el-icon-s-home
"></i>
        <span
          slot="title">{{home.name}}</span>
      </el-menu-item>
      <div
        v-for="(item,index) in menuList"
        :key="index">
        <el-submenu
          v-if="item.path != '/xi'"
          :index="index + ''">
          <template
            slot="title">
            <i
              class="el-icon-location"></i>
            <span>{{item.name}}</span>
          </template>
          <el-menu-item-group
            v-for="(itemC,indexC) in item.children"
            :key="indexC">
            <el-menu-item
              @click="goPage(itemC)"
              :index="index +'-'+indexC + ''">{{itemC.name}}</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </div>
    </el-menu>
  </el-drawer>
  <!--弹窗 end-->
</template>

<script>
// 引入组件及方法
export default {
  name: 'Yxb3NewModal',
  data() {
    return {
      dialogVisible: false, // 弹窗显隐状态
      menuList: [],
      home: {}
    }
  },

  //  初始化
  mounted() {},

  methods: {
    // 弹窗信息设置
    open() {
      let nav = JSON.parse(localStorage.getItem('navList'))
      this.menuList = nav
      this.home = this.menuList.shift()
      console.log(this.menuList)
      console.log(this.home)
      this.dialogVisible = true
    },
    goPage(item) {
      this.$store.commit('PATH', item.path)
      this.$store.commit('ID', item.id)
      console.log(item)
      this.$router.push({
        path: item.path,
        query: { id: item.id }
      })
      this.dialogVisible = false
    },
    handleClose() {
      this.dialogVisible = false
    }
  }
}
</script>
<style lang="scss">
</style>
