<!--
 * @Description:: 请补充填写
 * @Version: 请配置版本号
 * @Author: xiaobai
 * @Date: 2021-06-10 17:34:11
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-11-02 11:27:53
 * @FilePath: \sipingnongzhuan2\src\components\back_top.vue
-->
<template>
  <div class="back_top"
    v-if="show">
    <!-- <div
      class="back_top_item back_top_item_a">
    </div> -->
    <div class="back_top_item"
      @click="goTop()">
      <img
        src="../assets/imgaes/top.png"
        style="width:40px">
    </div>
  </div>
</template>

<script>
import linkIcon from '../assets/imgaes/fenxiang.png'
export default {
  data() {
    return {
      linkIcon,
      show: false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.getHtmlBodyHeight)
  },
  methods: {
    getHtmlBodyHeight() {
      //获取滚动时的高度
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 120) {
        this.show = true
      } else {
        this.show = false
      }
    },
    goTop() {
      var timer = null
      cancelAnimationFrame(timer)
      timer = requestAnimationFrame(function fn() {
        var oTop = document.body.scrollTop || document.documentElement.scrollTop
        if (oTop > 0) {
          document.body.scrollTop = document.documentElement.scrollTop = oTop - 50
          timer = requestAnimationFrame(fn)
        } else {
          cancelAnimationFrame(timer)
        }
      })
    }
  }
}
</script>

<style>
.back_top {
  position: fixed;
  right: 0;
  bottom: 100px;
  z-index: 9999;
  width: 60px;
  height: 60px;
  border: 1px solid #d9d9d9;
  border-radius: 60px 0 0 60px;
  background: #f0f0f0;
  transition: all 1s ease-out;
  display: flex;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  text-align: center;
  cursor: pointer;
}
/* .back_top:hover {
  cursor: pointer;
  width: 180px;
}
.back_top:hover .back_top_item_a {
  display: block;
} */
.back_top_item {
  padding: 10px;
  width: 100%;
}
.back_top_item_a {
  display: none;
}
</style>