<!--
 * @Author: xiaobaobeibai 604070135@qq.com
 * @Date: 2021-06-15 11:09:50
 * @LastEditors: smallWhite
 * @LastEditTime: 2024-02-26 08:48:53
 * @FilePath: \sipingnongzhuan2\src\views\home\index2.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="index_box">
    <div
      v-if="isPhone === 'true'">
      <headerVue></headerVue>
      <!-- banner mobile-->
      <div
        class="banner_box_index">
        <el-carousel
          height="400px"
          trigger="click">
          <el-carousel-item>
            <img
              src="../../assets/imgaes/banner_03.png"
              style="width:100%;height:400px">
          </el-carousel-item>
          <el-carousel-item>
            <img
              src="../../assets/imgaes/banner_04.png"
              style="width:100%;height:400px">
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- banner mobile-->
    </div>
    <!-- banner pc-->
    <div
      v-if="isPhone === 'false'">
      <headerVue></headerVue>
      <div
        class="banner_box_index">
        <el-carousel
          height="350px"
          trigger="click">
          <el-carousel-item>
            <img
              src="../../assets/imgaes/banner_03.png"
              style="width:100%;height:350px">
          </el-carousel-item>
          <el-carousel-item>
            <img
              src="../../assets/imgaes/banner_04.png"
              style="width:100%;height:350px">
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- banner pc小图标-->
    <div
      v-if="isPhone === 'false'">
      <div
        class="banner_box_index">
        <div
          class="bg-white bg_img">
          <div
            class="yewu_box">
            <div
              style="overflow-x: auto;">
              <div
                class="yewu_box_item_box">
                <div
                  class="yewu_box_item"
                  v-for="(item,index) in projectLists"
                  :key="index">
                  <div
                    class="yewu"
                    :style="item.style">
                    <div
                      class="card_a">
                      <img
                        :src="item.url"
                        class="card_img">
                      <div
                        class="yewu_title">
                        {{item.name}}
                      </div>
                    </div>
                    <div
                      class="yewu_content">
                      <span
                        @click="gotoYewu(index)"
                        class="yewu_btn">查看详情</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div
          class="sy_tubiao">
          <div
            class="tubiao_center">
            <div
              class="tubiao_text">
              <div
                class="tubiao_i">
                <i
                  class="el-icon-document"></i>
              </div>
              <p>学校章程</p>
            </div>
            <div
              class="tubiao_text">
              <div
                class="tubiao_i">
                <i
                  class="el-icon-magic-stick"></i>
              </div>
              <p>专业设置</p>
            </div>
            <div
              class="tubiao_text">
              <div
                class="tubiao_i">
                <i
                  class="el-icon-reading"></i>
              </div>
              <p>规章制度</p>
            </div>
            <div
              class="tubiao_text">
              <div
                class="tubiao_i">
                <i
                  class="el-icon-chat-line-square"></i>
              </div>
              <p>招生资讯</p>
            </div>
            <div
              class="tubiao_text">
              <div
                class="tubiao_i">
                <i
                  class="el-icon-school"></i>
              </div>
              <p>政策法规</p>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <!-- banner pc小图标下面的-->
    <!-- <div
      v-if="isPhone === 'false'">
      <div
        class="banner_xinwen">
        <h3>校园动态和招考政策</h3>
        <div
          class="xinwen_icon">
        </div>
        <el-tabs
          class="xinwen_tabs"
          v-model="activeName"
          @tab-click="handleClick">
          <el-tab-pane
            label="招考信息"
            name="a">招考信息</el-tab-pane>
          <el-tab-pane
            label="高等特殊教育"
            name="b">高等特殊教育</el-tab-pane>
        </el-tabs>
      </div>
    </div> -->
    <!-- banner pc-->
    <div class="bg-img"
      style="padding:1px 0 0;position: relative;">
      <!-- <div class="bg-img"
      style="padding:20px 0 0;background: #fff"> -->
      <!-- 系部导航 -->
      <!-- <el-collapse-transition> -->
      <div class="bg_menu">
        <div
          class="menu_title">
          <i style="font-size:20px"
            class="el-icon-mouse"></i>
          系部导航
        </div>
        <el-menu
          default-active="1-4-1"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          :collapse="true">
          <el-submenu
            v-for="(item,index) in xiList"
            :key="index"
            :index="index">
            <template
              slot="title">
              <img
                style="width:18px;margin-right:3px"
                :src="item.img"
                alt="">
              <!-- <i style="text-align:right;display:inline"
                class="el-icon-arrow-right"></i> -->
              <span
                style="display: inline-block;width:90px;height:56px;font-size:16px;font-weight:500"
                slot="title">{{item.name}}</span>
              <i style="text-align:right;display:inline"
                class="el-icon-arrow-right"></i>
            </template>
            <div
              v-for="(item2,index2) in item.son"
              :key="index2">
              <el-menu-item-group
                v-if="item2.label != '专业设置'"
                style="margin-top:-7px">
                <el-menu-item
                  index="1-1">{{item2.label}}</el-menu-item>
              </el-menu-item-group>
              <el-submenu
                v-if="item2.child && item2.child.length >0"
                index="1-4">
                <span
                  slot="title">专业设置</span>
                <el-menu-item
                  v-for="(item3,index3) in item2.child"
                  :key="index3"
                  index="1-4-2">{{item3.xi}}</el-menu-item>
              </el-submenu>
            </div>
          </el-submenu>
          <el-menu-item
            style="font-size:16px;font-weight:500"
            index="4">
            <img
              style="width:18px;margin-right:3px"
              src="../../assets/imgaes/xiaotubiao6.png"
              alt="">
            <span>思政教研室</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="bg_menu2">
        <div
          class="menu_title2">
          <i style="font-size:20px"
            class="el-icon-bell"></i>
          通知公告
        </div>
        <ul>
          <li
            v-for="(item,index) in newsList"
            :key="index">
            <i
              class="el-icon-message-solid"></i>&nbsp;{{item.title}}
          </li>
        </ul>
      </div>
      <!-- </el-collapse-transition> -->
      <!-- 系部导航 -->
      <!-- <el-card class="card"
        shadow="never">
        <el-row :gutter="20">
          <el-col :lg="8"
            :md="12" :sm="24"
            v-if="isPhone === 'false'">
            <el-carousel
              height="280px"
              v-loading="loadingImg"
              arrow="nerver"
              direction="vertical">
              <el-carousel-item
                v-for="item in newsListImg"
                :key="item.id">
                <img
                  :src="item.imgUrl"
                  style="width:100%;height:100%">
                <div
                  class="news_footer">
                  {{item.title}}
                </div>
                <div
                  class="lunbo"
                  @click="gotoNews(item)">
                </div>
              </el-carousel-item>
            </el-carousel>
          </el-col>
          <el-col :lg="12"
            :md="12" :sm="24"
            v-else>
            <el-carousel
              height="220px"
              v-loading="loadingImg"
              arrow="nerver"
              direction="vertical">
              <el-carousel-item
                v-for="item in newsListImg"
                :key="item.id">
                <img
                  :src="item.imgUrl"
                  style="width:100%;height:100%">
                <div
                  class="news_footer">
                  {{item.title}}
                </div>
                <div
                  class="lunbo"
                  @click="gotoNews(item)">
                </div>
              </el-carousel-item>
            </el-carousel>
          </el-col>
          <el-col :lg="12"
            :md="12" :sm="24"
            v-if="isPhone === 'false'">
            <div
              class="news_nav">
              <div
                class="news_nav_left">
                <div
                  class="news_nav_item"
                  @click="changeNavs(index)"
                  :class="{active:isActive === index}"
                  v-for="(item,index) in news_navs"
                  :key="index">
                  {{item.name}}
                </div>
              </div>
              <div
                class="news_nav_right">
                <span
                  @click="gotoNewList(isActive)">更多>></span>
              </div>
            </div>
            <div
              class="nav_body"
              v-loading="loadingB">
              <div
                class="nav_item"
                @click="gotoNewes(item,isActive)"
                v-for="(item,index) in newsList"
                :key="index">
                <div
                  class="nav_item_left">
                  <div
                    class="icon">
                    <i class="el-icon-s-opportunity"
                      style="color:rgb(14, 73, 141)"></i>
                  </div>
                  <div
                    class="news_title">
                    {{item.title}}
                  </div>
                </div>
                <div
                  class="nav_item_right">
                  <span>{{item.time.substring(0,11)}}</span>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :lg="12"
            :md="12" :sm="24"
            style="margin-top:20px"
            v-else>
            <div
              class="news_nav">
              <div
                class="news_nav_left">
                <div
                  class="news_nav_item"
                  style="padding:0 0px;"
                  @click="changeNavs(index)"
                  :class="{active:isActive === index}"
                  v-for="(item,index) in news_navs"
                  :key="index">
                  {{item.name}}
                </div>
              </div>
            </div>
            <div
              class="nav_body"
              v-loading="loadingB">
              <div
                class="nav_item"
                @click="gotoNewes(item,isActive)"
                v-for="(item,index) in newsList"
                :key="index">
                <div
                  class="nav_item_left">
                  <div
                    class="icon">
                    <i class="el-icon-s-opportunity"
                      style="color:rgb(14, 73, 141)"></i>
                  </div>
                  <div
                    class="news_title">
                    {{item.title}}
                  </div>
                </div>
                <div
                  class="nav_item_right">
                  <span>{{item.time.substring(0,11)}}</span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-card> -->
      <el-card class="card"
        shadow="never"
        style="margin:20px auto">
        <el-row :gutter="20">
          <el-col :lg="10"
            :md="12" :sm="24">
            <!-- <div
              class="card_header">
              <div
                class="card_title">
                校园风光
              </div>
              <div
                class="card_more">
                <span
                  @click="gotoNewListss(3)">更多>></span>
              </div>
            </div> -->
            <!-- <el-carousel
              style="margin-top:10px"
              height="225px"
              v-loading="loadingImg"
              arrow="nerver"
              direction="vertical">
              <el-carousel-item
                v-for="item in newsListImg"
                :key="item.id">
                <img
                  :src="item.imgUrl"
                  style="width:100%;height:100%">
                <div
                  class="news_footer">
                  {{item.title}}
                </div>
                <div
                  class="lunbo"
                  @click="gotoNews(item)">
                </div>
              </el-carousel-item>
            </el-carousel> -->
            <img
              src="https://www.cdu.edu.cn/attachment/a4d/1/202007/20200727223000n911vECK7f.jpg"
              style="width:100%;height:100%;margin-top:10px">
          </el-col>
          <el-col :lg="14"
            :md="12" :sm="24"
            v-if="isPhone === 'false'">
            <div
              class="card_header">
              <div
                class="card_title">
                校园新闻
              </div>
              <div
                class="card_more">
                <span
                  @click="gotoNewListss(4)">更多>></span>
              </div>
            </div>
            <div
              class="nav_body"
              v-loading="loadingF">
              <div
                v-if="newsListF.length === 0"
                style="line-height:200px;text-align:center">
                暂无数据
              </div>
              <div v-else
                class="nav_item"
                @click="gotoOtherPage(item)"
                v-for="(item,index) in newsListF"
                :key="index">
                <div
                  class="nav_item_left">
                  <div
                    class="icon">
                    <i class="el-icon-s-opportunity"
                      style="color:rgb(14, 73, 141)"></i>
                  </div>
                  <div
                    class="news_title">
                    {{item.title}}
                  </div>
                </div>
                <div
                  class="nav_item_right">
                  <span>{{item.time.substring(0,11)}}</span>
                </div>
              </div>

            </div>
          </el-col>
          <el-col :lg="8"
            :md="12" :sm="24"
            v-else
            style="margin:20px 0">
            <div
              class="card_header">
              <div
                class="card_title">
                学校章程
              </div>
              <div
                class="card_more">
                <span
                  @click="gotoNewListss(4)">更多>></span>
              </div>
            </div>
            <div
              class="nav_body"
              v-loading="loadingF">
              <div
                class="nav_item"
                @click="gotoOtherPage(item)"
                v-for="(item,index) in newsListF"
                :key="index">
                <div
                  class="nav_item_left">
                  <div
                    class="icon">
                    <i class="el-icon-s-opportunity"
                      style="color:rgb(14, 73, 141)"></i>
                  </div>
                  <div
                    class="news_title">
                    {{item.title}}
                  </div>
                </div>
                <div
                  class="nav_item_right">
                  <span>{{item.time.substring(0,11)}}</span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="card"
        shadow="never"
        style="margin:20px auto">
        <el-row :gutter="20">
          <el-col :lg="8"
            :md="12" :sm="24">
            <div
              class="card_header">
              <div
                class="card_title">
                常用链接
              </div>
              <!-- <div
                class="card_more">
                <span
                  @click="gotoNewListss(3)">更多>></span>
              </div> -->
            </div>
            <div
              class="card_body"
              style="margin-top:60px;display:flex">
              <div
                @click="goNet(1)"
                style="background: #00bac7"
                class="card_body_div">
                <img
                  class="card_body_img"
                  src="../../assets/imgaes/index_icon1.png"
                  alt="">
                <div
                  class="card_body_p">
                  学信网
                </div>
              </div>
              <div
                @click="goNet(2)"
                style="background: rgb(254, 192, 47)"
                class="card_body_div">
                <img
                  class="card_body_img"
                  src="../../assets/imgaes/index_icon4.png"
                  alt="">
                <div
                  class="card_body_p">
                  吉林省教育考试院
                </div>
              </div>
              <div
                @click="goNet(3)"
                style="background: #0072FF"
                class="card_body_div">
                <img
                  class="card_body_img"
                  src="../../assets/imgaes/logo.png"
                  alt="">
                <div
                  class="card_body_p">
                  亿学宝
                </div>
              </div>
            </div>
          </el-col>
          <el-col :lg="8"
            :md="12" :sm="24"
            v-if="isPhone === 'false'">
            <div
              class="card_header">
              <div
                class="card_title">
                党团活动
              </div>
              <div
                class="card_more">
                <span
                  @click="gotoNewListss(4)">更多>></span>
              </div>
            </div>
            <div
              class="nav_body"
              v-loading="loadingF">
              <div
                v-if="newsListF.length === 0"
                style="line-height:200px;text-align:center">
                暂无数据
              </div>
              <div v-else
                class="nav_item"
                @click="gotoOtherPage(item)"
                v-for="(item,index) in newsListG"
                :key="index">
                <div
                  class="nav_item_left">
                  <div
                    class="icon">
                    <i class="el-icon-s-opportunity"
                      style="color:rgb(14, 73, 141)"></i>
                  </div>
                  <div
                    class="news_title">
                    {{item.title}}
                  </div>
                </div>
                <div
                  class="nav_item_right">
                  <span>{{item.time.substring(0,11)}}</span>
                </div>
              </div>

            </div>
          </el-col>
          <el-col :lg="8"
            :md="12" :sm="24"
            v-else
            style="margin:20px 0">
            <div
              class="card_header">
              <div
                class="card_title">
                学校章程
              </div>
              <div
                class="card_more">
                <span
                  @click="gotoNewListss(4)">更多>></span>
              </div>
            </div>
            <div
              class="nav_body"
              v-loading="loadingF">
              <div
                class="nav_item"
                @click="gotoOtherPage(item)"
                v-for="(item,index) in newsListF"
                :key="index">
                <div
                  class="nav_item_left">
                  <div
                    class="icon">
                    <i class="el-icon-s-opportunity"
                      style="color:rgb(14, 73, 141)"></i>
                  </div>
                  <div
                    class="news_title">
                    {{item.title}}
                  </div>
                </div>
                <div
                  class="nav_item_right">
                  <span>{{item.time.substring(0,11)}}</span>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :lg="8"
            :md="12" :sm="24">
            <div
              class="card_header">
              <div
                class="card_title">
                教学动态
              </div>
              <div
                class="card_more">
                <span
                  @click="gotoNewList(-1)">更多>></span>
              </div>
            </div>
            <div
              class="nav_body"
              v-loading="loadingF">
              <div
                class="nav_item"
                @click="gotoOtherPage(item)"
                v-for="(item,index) in newsListG"
                :key="index">
                <div
                  class="nav_item_left">
                  <div
                    class="icon">
                    <i class="el-icon-s-opportunity"
                      style="color:rgb(14, 73, 141)"></i>
                  </div>
                  <div
                    class="news_title">
                    {{item.title}}
                  </div>
                </div>
                <div
                  class="nav_item_right">
                  <span>{{item.time.substring(0,11)}}</span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-card>
      <div class="blue_box">
        <div
          class="blue_1200">
          <div
            class="card_header">
            <div
              class="card_title">
              校园文化
            </div>
            <div
              class="card_more">
              <span
                @click="gotoProject(4,0)">更多>></span>
            </div>
          </div>

          <div
            class="project_boxs"
            v-loading="loadingC"
            v-if="isPhone !=='true'">
            <el-carousel
              height="220px"
              indicator-position="outside"
              v-loading="loadingC"
              :interval="3000"
              arrow="always">
              <el-carousel-item
                v-for="item in projectList"
                :key="item.id">
                <el-row
                  :gutter="20">
                  <el-col
                    :md="8"
                    v-for="(k,s) in item"
                    :key="s">
                    <div
                      class="item_boxes">
                      <img
                        :src="k.imgUrl">
                      <div
                        class="item_footer">
                        {{k.name}}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div
            class="project_boxs"
            v-loading="loadingC"
            v-if="isPhone ==='true'">
            <el-carousel
              height="220px"
              indicator-position="outside"
              v-loading="loadingC"
              arrow="always">
              <el-carousel-item
                v-for="item in projectList"
                :key="item.id">
                <div
                  class="item_boxes">
                  <img
                    :src="item.imgUrl">
                  <div
                    class="item_footer">
                    {{item.name}}
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <BackTop></BackTop>
  </div>

</template>

<script>
import headerVue from '../../components/header_index.vue'
import Footer from '../../components/footer'
import BackTop from '../../components/back_top'
import axios from 'axios'
import ProjectIcon1 from '../../assets/imgaes/index_icon1.png'
import ProjectIcon2 from '../../assets/imgaes/index_icon2.png'
import ProjectIcon3 from '../../assets/imgaes/index_icon3.png'
import ProjectIcon4 from '../../assets/imgaes/index_icon4.png'
import ProjectIcon5 from '../../assets/imgaes/index_icon5.png'
import xiaotubiao1 from '../../assets/imgaes/xiaotubiao1.png'
import xiaotubiao2 from '../../assets/imgaes/xiaotubiao2.png'
import xiaotubiao3 from '../../assets/imgaes/xiaotubiao3.png'
import xiaotubiao4 from '../../assets/imgaes/xiaotubiao4.png'
import xiaotubiao5 from '../../assets/imgaes/xiaotubiao5.png'

export default {
  components: { headerVue, Footer, BackTop },
  data() {
    return {
      scrollTop: 0,
      showMenu: true,
      loadingImg: false,
      loadingA: false,
      loadingB: false,
      loadingC: false,
      loadingVideo: false,
      loadingF: false,
      loadingaaa: false,
      loadingbbb: false,
      newsUrl: '',
      isPhone: 'false',
      isActive: 0,
      projectWidth: '',
      newsListImg: [
        {
          id: '1',
          imgUrl: 'https://www.cdu.edu.cn/attachment/a4d/1/202007/20200727223000n911vECK7f.jpg',
          title: '教学楼'
        },
        {
          id: '2',
          imgUrl: 'https://img1.baidu.com/it/u=1950791653,2490835845&fm=253&fmt=auto&app=120&f=JPEG?w=1160&h=800',
          title: '校内湖畔'
        },
        {
          id: '3',
          imgUrl: 'https://www.cqeec.com/2017/images/20171109094323pRkKmRiG.jpg',
          title: '操场落日'
        }
      ],
      newsList: [
        {
          title: '四平农专XX学科建立10周年庆祝会举行',
          time: '2023-10-20 10:35:22'
        },
        {
          title: '教育学院深入开展习近平文化思想专题学习',
          time: '2023-10-20 10:35:22'
        },
        {
          title: '回首来时路，悠悠五大洲',
          time: '2023-10-20 10:35:22'
        },
        {
          title: '汇丰教授参加2023丹东三校研讨会',
          time: '2023-10-20 10:35:22'
        },
        {
          title: '探讨商业生态系统面临的挑战',
          time: '2023-10-20 10:35:22'
        },
        {
          title: '示范学生宿舍评选活动即将举行',
          time: '2023-10-20 10:35:22'
        },
        {
          title: '惠及民生的合作项目连续开展',
          time: '2023-10-20 10:35:22'
        },
        {
          title: '“一带一路”上的人民故事',
          time: '2023-10-20 10:35:22'
        },
        {
          title: '教育学院深入开展习近平文化思想专题学习',
          time: '2023-10-20 10:35:22'
        },
        {
          title: '回首来时路，悠悠五大洲',
          time: '2023-10-20 10:35:22'
        },
        {
          title: '汇丰教授参加2023丹东三校研讨会',
          time: '2023-10-20 10:35:22'
        },
        {
          title: '探讨商业生态系统面临的挑战',
          time: '2023-10-20 10:35:22'
        },
        {
          title: '示范学生宿舍评选活动即将举行',
          time: '2023-10-20 10:35:22'
        },
        {
          title: '惠及民生的合作项目连续开展',
          time: '2023-10-20 10:35:22'
        },
        {
          title: '汇丰教授参加2023丹东三校研讨会',
          time: '2023-10-20 10:35:22'
        }
      ],
      newsListF: [
        {
          title: '“一带一路”上的人民故事',
          time: '2023-10-20 10:35:22'
        },
        {
          title: '探讨商业生态系统面临的挑战',
          time: '2023-10-20 10:35:22'
        },
        {
          title: '示范学生宿舍评选活动即将举行',
          time: '2023-10-20 10:35:22'
        },
        {
          title: '惠及民生的合作项目连续开展',
          time: '2023-10-20 10:35:22'
        },
        {
          title: '教育学院深入开展习近平文化思想专题学习',
          time: '2023-10-20 10:35:22'
        },
        {
          title: '教育学院深入开展习近平文化思想专题学习',
          time: '2023-10-20 10:35:22'
        }
      ],
      newsListG: [
        {
          title: '四平农专XX学科建立10周年庆祝会举行',
          time: '2023-10-20 10:35:22'
        },
        {
          title: '教育学院深入开展习近平文化思想专题学习',
          time: '2023-10-20 10:35:22'
        },
        {
          title: '回首来时路，悠悠五大洲',
          time: '2023-10-20 10:35:22'
        },
        {
          title: '汇丰教授参加2023丹东三校研讨会',
          time: '2023-10-20 10:35:22'
        },
        {
          title: '探讨商业生态系统面临的挑战',
          time: '2023-10-20 10:35:22'
        }
      ],
      projectLists: [
        {
          id: 1,
          name: '学校章程',
          url: ProjectIcon1,
          style: 'background: rgb(29, 104, 189)'
        },
        {
          id: 2,
          name: '专业设置',
          url: ProjectIcon2,
          style: 'background: rgb(105, 186, 110)'
        },
        {
          id: 3,
          name: '招生快讯',
          url: ProjectIcon3,
          style: 'background: rgb(52, 198, 217)'
        },
        {
          id: 4,
          name: '教师风采',
          url: ProjectIcon4,
          style: 'background: rgb(254, 192, 47)'
        },
        {
          id: 5,
          name: '政策法规',
          url: ProjectIcon5,
          style: 'background: rgb(252, 122, 148)'
        }
      ],
      projectList: [
        [
          {
            id: '6719967013143318528',
            createTime: '2020-10-26 10:35:22',
            projectId: '6708373195717611520',
            name: '团结协助互相学习',
            url: '20201026/1603679720712.jpg',
            isEnable: 0,
            sort: 34,
            content: '<p>团结协助互相学习</p>',
            imgUrl: 'https://p1.itc.cn/q_70/images03/20211027/3cd630533ae74c35829ff93e23da047d.png'
          },
          {
            id: '6719966865512206336',
            createTime: '2020-10-26 10:34:47',
            projectId: '6708373128956874752',
            name: '学校正能量激励板',
            url: '20201026/1603679685104.jpg',
            isEnable: 0,
            sort: 33,
            content: '<p>学校正能量激励板</p>',
            imgUrl: 'https://5b0988e595225.cdn.sohucs.com/images/20190927/433293c1e0464e80ad678f93acfc4067.jpeg'
          },
          {
            id: '6719966802056581120',
            createTime: '2020-10-26 10:34:32',
            projectId: '6708373128956874752',
            name: '诗书文化墙',
            url: '20201026/1603679669807.png',
            isEnable: 0,
            sort: 32,
            content: '<p>诗书文化墙</p>',
            imgUrl:
              'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fsafe-img.xhscdn.com%2Fbw1%2F6cd91f50-f7d8-4635-a60c-cfc7b6ade4ff%3FimageView2%2F2%2Fw%2F1080%2Fformat%2Fjpg&refer=http%3A%2F%2Fsafe-img.xhscdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1700379504&t=bd3609503626d68a04bb7c001abf4562'
          }
        ],
        [
          {
            id: '6719966802056581120',
            createTime: '2020-10-26 10:34:32',
            projectId: '6708373128956874752',
            name: '书法大赛',
            url: '20201026/1603679669807.png',
            isEnable: 0,
            sort: 32,
            content: '<p>书法大赛</p>',
            imgUrl: 'https://img2.baidu.com/it/u=2038826431,3640746852&fm=253&fmt=auto&app=138&f=JPEG?w=667&h=500'
          },
          {
            id: '6719966802056581120',
            createTime: '2020-10-26 10:34:32',
            projectId: '6708373128956874752',
            name: '武术运动文化节',
            url: '20201026/1603679669807.png',
            isEnable: 0,
            sort: 32,
            content: '<p>武术运动文化节</p>',
            imgUrl: 'https://5b0988e595225.cdn.sohucs.com/images/20190618/b35887c3b8a54f8b94c28912f95a752d.jpeg'
          },
          {
            id: '6719966802056581120',
            createTime: '2020-10-26 10:34:32',
            projectId: '6708373128956874752',
            name: '国庆节传统服饰校园文化活动',
            url: '20201026/1603679669807.png',
            isEnable: 0,
            sort: 32,
            content: '<p>国庆节传统服饰校园文化活动</p>',
            imgUrl: 'https://p6.itc.cn/q_70/images03/20220526/c9cdeee62bca4963926af2b8e4c62a4c.jpeg'
          }
        ]
      ],
      projectId: '',
      activeName: 'a',
      projectParentId: '6708371247165607936',
      news_navs: [{ name: '教学动态' }, { name: '通知公告' }],
      xiList: [
        {
          name: '农业工程系',
          img: xiaotubiao1,
          son: [
            { label: '专业设置', child: [{ xi: '作物生产与经营管理' }, { xi: '林业技术' }, { xi: '园林技术' }, { xi: '水利工程' }] },
            { label: '系部简介' },
            { label: '机构设置' },
            { label: '师资力量' },
            { label: '人才培养方案' },
            { label: '教学管理' },
            { label: '教学教研' }
          ]
        },
        {
          name: '牧医系',
          img: xiaotubiao2,
          son: [
            { label: '专业设置', child: [{ xi: '畜牧兽医' }, { xi: '食品智能加工技术' }] },
            { label: '系部简介' },
            { label: '机构设置' },
            { label: '师资力量' },
            { label: '人才培养方案' },
            { label: '教学管理' },
            { label: '教学教研' }
          ]
        },
        {
          name: '财经系',
          img: xiaotubiao3,
          son: [
            {
              label: '专业设置',
              child: [
                { xi: '大数据与会计' },
                { xi: '国际经济与贸易' },
                { xi: '工商企业管理' },
                { xi: '现代物流管理' },
                { xi: '国土资源调查与管理' }
              ]
            },
            { label: '系部简介' },
            { label: '机构设置' },
            { label: '师资力量' },
            { label: '人才培养方案' },
            { label: '教学管理' },
            { label: '教学教研' }
          ]
        },
        {
          name: '人文系',
          img: xiaotubiao4,
          son: [
            { label: '专业设置', child: [{ xi: '法律事务' }, { xi: '社会工作' }, { xi: '戏曲表演' }, { xi: '新闻采编与制作' }] },
            { label: '系部简介' },
            { label: '机构设置' },
            { label: '师资力量' },
            { label: '人才培养方案' },
            { label: '教学管理' },
            { label: '教学教研' }
          ]
        },
        {
          name: '计算机系',
          img: xiaotubiao5,
          son: [
            {
              label: '专业设置',
              child: [
                { xi: '计算机应用技术' },
                { xi: '机电一体化技术' },
                { xi: '机械制造及自动化' },
                { xi: '供用电技术' },
                { xi: '城乡规划' }
              ]
            },
            { label: '系部简介' },
            { label: '机构设置' },
            { label: '师资力量' },
            { label: '人才培养方案' },
            { label: '教学管理' },
            { label: '教学教研' }
          ]
        }
      ]
    }
  },
  mounted() {
    // window.addEventListener('scroll', this.getHtmlBodyHeight)
    // setTimeout(() => {
    // console.log(this.$store.state.navList[4].id, '000')
    // this.projectParentId = this.$store.state.navList[4].id
    if (window.localStorage.getItem('isPhone')) this.isPhone = window.localStorage.getItem('isPhone')
    else this.isPhone = 'false'
    // this.getNewsImg()
    // this.getNews()
    // this.getNewsVideo()
    // this.getNewsF()
    // this.getNewsG()
    // this.getProject()
    // this.getHtmlBodyHeight()
    // }, 1000)
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    },
    getProject() {
      // this.loadingC = true
      this.projectList = []
      axios
        .post('/home/engineering/case', {
          projectParentId: this.projectParentId,
          pageNum: 1,
          pageSize: 20
        })
        .then(res => {
          const resourcesDtoList = res.data.data.resourcesDtoList
          if (resourcesDtoList.length > 9) {
            resourcesDtoList.map(item => this.$set(item, 'imgUrl', res.data.data.imgUrl + '/' + item.url))
            // this.projectList = resourcesDtoList.slice(0,9)
            if (this.isPhone !== 'true') {
              const arr0 = resourcesDtoList.slice(0, 3)
              const arr1 = resourcesDtoList.slice(3, 6)
              const arr2 = resourcesDtoList.slice(6, 9)
              const arr3 = resourcesDtoList.slice(9, 12)
              const arr4 = resourcesDtoList.slice(12, 15)
              this.projectList.push(arr0, arr1, arr2, arr3, arr4)
            } else {
              this.projectList = resourcesDtoList.slice(0, 6)
            }
            console.log(this.projectList)
          } else {
            resourcesDtoList.map(item => this.$set(item, 'imgUrl', res.data.data.imgUrl + '/' + item.url))
            this.projectList = resourcesDtoList
            console.log(this.projectList)
          }
          this.loadingC = false
        })
    },
    gotoProject(i, j) {
      this.$store.commit('setOpenNav', [i, j])
      this.$router.push({
        path: '/project_way_index'
      })
    },
    getNewsImg() {
      // this.loadingImg = true
      // axios.post('/home/newNews', { pageNum: 1, pageSize: 3, type: 2 }).then(res => {
      //   this.newsListImg = res.data.data.newsList
      //   console.log(this.newsListImg)
      //   this.newsListImg.map(item => {
      //     this.$set(item, 'imgUrl', res.data.data.imgUrl + '/' + item.url)
      //   })
      //   console.log(this.newsListImg)
      //   this.loadingImg = false
      // })
    },
    getNews() {
      // this.loadingB = true
      axios.post('/home/newNews', { pageNum: 1, pageSize: 8, type: 2 }).then(res => {
        this.newsList = res.data.data.newsList
        console.log(this.newsList)
        this.newsList.map(item => {
          this.$set(item, 'imgUrl', res.data.data.imgUrl + '/' + item.url)
        })
        this.loadingB = false
      })
    },
    getNewsA() {
      // this.loadingaaa = true
      axios.post('/home/newNews', { pageNum: 1, pageSize: 8, type: 3 }).then(res => {
        this.newsList = res.data.data.newsList
        this.newsList.map(item => {
          this.$set(item, 'imgUrl', res.data.data.imgUrl + '/' + item.url)
        })
        this.loadingaaa = false
      })
    },
    getNewsB() {
      // this.loadingB = true
      axios
        .post('/home/engineering/case', {
          pageNum: 1,
          projectId: '6718156750056329216',
          pageSize: 8
        })
        .then(res => {
          this.newsList = res.data.data.resourcesDtoList
          this.newsList.map(item => {
            this.$set(item, 'imgUrl', res.data.data.imgUrl + '/' + item.url)
            this.$set(item, 'time', item.createTime)
            this.$set(item, 'title', item.name)
          })
          if (this.newsList.length > 8) {
            this.newsList.splice(8, this.newsList.length)
          }
          this.loadingB = false
        })
    },
    gotoNews(item) {
      console.log(item)
      // this.$store.commit('setOpenNav', [6, item.type - 1])
      // this.$router.push({
      //   path: '/news_details',
      //   query: {
      //     id: item.id
      //   }
      // })
    },

    gotoNewes(e, i) {
      if (i === 2) {
        this.$store.commit('setOpenNav', [7, 2])
        this.$router.push({
          path: '/team_building_details',
          query: {
            item: JSON.stringify(e)
          }
        })
      } else {
        this.gotoNews(e)
      }
    },
    gotoNewList(index) {
      console.log(index)
      // if (index !== 2) {
      //   this.$store.commit('setOpenNav', [6, index + 1])
      //   this.$router.push({
      //     path: '/company_news'
      //   })
      // } else {
      //   this.gotoNewes('', 2)
      // }
    },
    gotoNewListss(e) {
      console.log(e)
      // this.$store.commit('setOpenNav', [6, e])
      // if (e === 0) {
      //   this.$router.push({
      //     path: '/industry_news'
      //   })
      // } else if (e == 1) {
      //   this.$router.push({
      //     path: '/company_news'
      //   })
      // } else if (e == 2) {
      //   this.$router.push({
      //     path: '/party_building'
      //   })
      // } else if (e == 3) {
      //   this.$router.push({
      //     path: '/video_news'
      //   })
      // } else if (e == 4) {
      //   this.$router.push({
      //     path: '/shape'
      //   })
      // }
    },
    getNewsVideo() {
      // this.loadingVideo = true
      axios.post('/home/newNews', { pageNum: 1, pageSize: 1, type: 4 }).then(res => {
        const arr = res.data.data.newsList
        if (arr.length > 0) {
          arr.map(item => {
            this.$set(item, 'imgUrl', res.data.data.imgUrl + '/' + item.url)
          })
          this.newsUrl = arr[0].imgUrl
        }

        // this.loadingVideo = false
      })
    },
    getNewsG() {
      axios.post('/home/newNews', { pageNum: 1, pageSize: 6, type: 1 }).then(res => {
        this.newsListG = res.data.data.newsList
        this.newsListG.map(item => {
          this.$set(item, 'imgUrl', res.data.data.imgUrl + '/' + item.url)
        })
      })
    },
    getNewsF() {
      // this.loadingF = true
      axios.post('/home/newNews', { pageNum: 1, pageSize: 6, type: 5 }).then(res => {
        this.newsListF = res.data.data.newsList
        this.loadingF = false
      })
    },
    gotoOtherPage(e) {
      // window.open(e.introduction)
      console.log(e)
    },
    changeNavs(index) {
      this.newsList = []
      this.isActive = index
      if (index === 0) {
        this.getNews()
      } else if (index === 1) {
        this.getNewsA()
      } else if (index === 2) {
        this.getNewsB()
      }
    },
    getHtmlBodyHeight() {
      //获取滚动时的高度
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      console.log(this.scrollTop)
      if (this.scrollTop > 600) {
        this.showMenu = true
      } else {
        this.showMenu = false
      }
      // if (this.scrollTop > 0) {
      //   this.goTop()
      //   this.scrollTop = 0
      // }
    },
    goTop() {
      var timer = null
      cancelAnimationFrame(timer)
      timer = requestAnimationFrame(function fn() {
        var oTop = document.body.scrollTop || document.documentElement.scrollTop
        if (oTop > 0) {
          document.body.scrollTop = document.documentElement.scrollTop = oTop - 50
          timer = requestAnimationFrame(fn)
        } else {
          cancelAnimationFrame(timer)
        }
      })
    },
    goNet(e) {
      if (e == 1) {
        window.open('https://www.chsi.com.cn/')
      }
      if (e == 2) {
        window.open('http://crgk.jleea.com.cn/')
      }
      if (e == 3) {
        window.open('https://yxb.yxbyun.com/#/login')
      }
    }
  }
}
</script>

<style scoped>
.banner_box_index {
  width: 100%;
  position: relative;
}
.banner_text {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card {
  max-width: 1200px;
  margin: 0px auto;
}
.news_footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  padding-left: 30px;
  background: linear-gradient(90deg, rgba(88, 179, 227, 0.4), rgba(169, 213, 79, 0.4));
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.lunbo {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  top: 0;
  left: 0;
  cursor: pointer;
}
.news_nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.news_nav .news_nav_left {
  display: flex;
}
.news_nav_right {
  color: #999999;
}
.news_nav_right:hover {
  color: rgb(14, 73, 141);
  cursor: pointer;
}
.news_nav .news_nav_left .news_nav_item {
  margin: 0 20px;
  line-height: 30px;
  border-bottom: 2px solid white;
  cursor: pointer;
  padding: 0 10px;
}
.news_nav .news_nav_left .news_nav_item.active {
  color: rgb(14, 73, 141);
  font-weight: 500;
  border-bottom: 2px solid rgb(14, 73, 141);
}
.nav_body {
  margin-top: 10px;
  min-height: 212px;
}
.nav_body .nav_item {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.nav_body .nav_item:hover {
  color: rgb(14, 73, 141);
  cursor: pointer;
}
.nav_item_left {
  display: flex;
  width: calc(100% - 110px);
}
.nav_item_left .icon i {
  color: #999999;
  font-size: 18px;
  margin-right: 10px;
}
.nav_item_left .news_title {
  overflow: hidden;
  height: 20px;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: normal;
}
.card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card_header .card_title {
  color: rgb(14, 73, 141);
  font-weight: bold;
  font-size: 18px;
  line-height: 36px;
  padding: 0 5px;
  border-bottom: 2px solid rgb(14, 73, 141);
}
.card_header .card_more {
  color: #999999;
  cursor: pointer;
}

.card_header .card_more:hover {
  color: rgb(14, 73, 141);
  cursor: pointer;
}
.blue_box {
  background: rgb(14, 73, 141);
  padding: 20px 0;
}
.blue_box .blue_1200 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  min-height: 220px;
  overflow: hidden;
}
.project_boxs .proe_access {
  width: 30px;
  height: 220px;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  position: absolute;
  left: 0;
  line-height: 220px;
  text-align: center;
  top: 0;
  z-index: 99;
}
.project_boxs .next_access {
  width: 30px;
  height: 220px;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  position: absolute;
  right: 0;
  line-height: 220px;
  text-align: center;
  top: 0;
  z-index: 99;
}
.project_boxs {
  position: relative;
  margin-top: 20px;
  overflow-y: hidden;
  height: 220px;
}
.blue_1200 .card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.blue_1200 .card_header .card_title {
  color: #fff;
}
.blue_1200 .card_header .card_more {
  color: #f1f1f1 !important;
}
.project_boxs .item_boxes {
  background: #fff;
  padding: 10px;
  position: relative;
}
.project_boxs .item_boxes img {
  height: 200px;
  width: 100%;
}
.project_boxs .item_boxes .item_footer {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  height: 40px;
  background: rgba(0, 0, 0, 0.2);
  line-height: 40px;
  color: #fff;
  padding-left: 20px;
}
/* 后加的 */
.banner_down {
  width: 100%;
  height: 40px;
  background: linear-gradient(90deg, #58b3e3, #a9d54f);
}
.sy_tubiao {
  height: 270px;
  width: 100%;
  background: #fff;
}
.tubiao_center {
  height: 250px;
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  /* cursor: pointer; */
}
.tubiao_text {
  text-align: center;
}
.tubiao_i {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  transition: 2s ease-out all;
  background: linear-gradient(90deg, #58b3e3, #a9d54f);
}
.tubiao_i :hover {
  width: 120px;
  height: 120px;
  cursor: pointer;
  transform: rotate(360deg);
  background: linear-gradient(90deg, #a9d54f, #58b3e3);
  border-radius: 5px;
}
.tubiao_text p {
  margin-top: 10px;
  color: rgb(14, 73, 141);
  font-size: 14px;
}
.tubiao_text i {
  color: #fff;
  font-size: 60px;
  line-height: 120px;
}
.banner_xinwen {
  width: 100%;
  background: #fff;
  overflow: hidden;
  position: relative;
}
.banner_xinwen h3 {
  text-align: center;
  font-size: 30px;
  font-weight: normal;
}
.xinwen_tabs {
  width: 1200px;
  margin: auto;
  margin-top: 60px;
}
.xinwen_tabs ::v-deep .el-tabs__nav-wrap::after {
  background-color: #fff;
}
.xinwen_tabs ::v-deep .el-tabs__active-bar {
  background-color: #fff;
}
.xinwen_tabs ::v-deep .el-tabs__item.is-active {
  color: #000;
}
.xinwen_tabs ::v-deep .el-tabs__item {
  color: rgb(153, 153, 153);
  font-size: 25px;
}
.xinwen_icon {
  width: 5px;
  height: 30px;
  background: linear-gradient(90deg, #a9d54f, #58b3e3);
  position: absolute;
  top: 135px;
  left: 343px;
}
.xinwen_tabs ::v-deep #tab-a {
  border-right: 1px solid rgb(153, 153, 153);
}
.bg_menu {
  width: 160px;
  height: 662px;
  position: absolute;
  top: 22px;
  left: calc((100% - 1200px) / 2 - 190px);
  z-index: 999;
  border: 5px solid #5084bf;
  border-radius: 5px;
}
.bg_menu .el-menu--collapse .el-submenu .el-submenu__title {
  background: red !important;
  text-align: right;
}
.bg_menu .el-menu--collapse .el-submenu .el-submenu__title span {
  visibility: initial;
  display: inline;
  /* color: rgb(14, 73, 141); */
}
.bg_menu .el-menu--collapse {
  width: 160px;
  height: 612px;
}
.bg_menu .el-menu--collapse .el-menu-item span {
  visibility: initial;
  display: inline;
  /* color: rgb(14, 73, 141); */
}
.bg-menu ::v-deep .el-menu {
  border-right: 0 !important;
  /* background: #f6fbff; */
  background: red;
}
.bg_menu2 {
  overflow: hidden;
  width: 200px;
  height: 662px;
  position: absolute;
  top: 22px;
  right: calc((100% - 1200px) / 2 - 230px);
  z-index: 999;
  border: 5px solid #5084bf;
  background: #f6fbff !important;
  border-radius: 5px;
}
.bg_menu2 .el-menu--collapse .el-submenu .el-submenu__title {
  background: red !important;
  text-align: right;
}
.bg_menu2 .el-menu--collapse .el-submenu .el-submenu__title span {
  visibility: initial;
  display: inline;
  /* color: rgb(14, 73, 141); */
}
.bg_menu2 .el-menu--collapse {
  width: 230px;
  height: 560px;
}
.bg_menu2 .el-menu--collapse .el-menu-item span {
  visibility: initial;
  display: inline;
  /* color: rgb(14, 73, 141); */
}
/* .bg_menu2 ul li:first-child {
  margin-top: 10px;
} */
/* .bg_menu2 ul {
  padding-left: 5px;
} */
.bg_menu2 ul li {
  padding: 10px 0;
  padding-left: 5px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
}
.bg_menu2 ul li:hover {
  color: rgb(14, 73, 141);
  background: #dae5f3;
}
.bg-menu2 ::v-deep .el-menu {
  border-right: 0 !important;
  /* background: #f6fbff; */
  background: red;
}
.menu_title {
  width: 160px;
  height: 50px;
  font-size: 19px;
  background: #5084bf;
  text-align: center;
  line-height: 50px;
  /* border-right: solid 1px #5084bf; */
  color: #fff;
}
.menu_title2 {
  width: 200px;
  height: 50px;
  font-size: 19px;
  background: #5084bf;
  text-align: center;
  line-height: 50px;
  /* border-right: solid 1px #5084bf; */
  color: #fff;
}
.card_body_div {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 0 20px 10px 20px;
  text-align: center;
  cursor: pointer;
}
.card_body_img {
  height: 45px;
  object-fit: contain;
  margin-top: 18px;
}
.card_body_p {
  font-size: 15px;
  margin-top: 20px;
}
.nav_item_right {
  font-size: 15px;
  color: #999;
}
</style>